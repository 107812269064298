/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface HealthControllerHealthCheck$Params {
}

export function healthControllerHealthCheck(http: HttpClient, rootUrl: string, params?: HealthControllerHealthCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'status'?: string;
'info'?: ({
[key: string]: {
'status': string;
[key: string]: {
};
};
}) | null;
'error'?: ({
[key: string]: {
'status': string;
[key: string]: {
};
};
}) | null;
'details'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
};
}>> {
  const rb = new RequestBuilder(rootUrl, healthControllerHealthCheck.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'status'?: string;
      'info'?: ({
      [key: string]: {
      'status': string;
      [key: string]: {
      };
      };
      }) | null;
      'error'?: ({
      [key: string]: {
      'status': string;
      [key: string]: {
      };
      };
      }) | null;
      'details'?: {
      [key: string]: {
      'status': string;
      [key: string]: {
      };
      };
      };
      }>;
    })
  );
}

healthControllerHealthCheck.PATH = '/api/health';
