import {EventEmitter, Inject, Injectable} from '@angular/core';
import {DOCUMENT, Location} from '@angular/common';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {RouterHelper} from '../utilities/router-helper';
import {AuthService} from '../security/auth.service';
import {MenuGroupEnum} from '../security/menu-group.enum';
import {MenuModel} from '../models/menu.model';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import {OverlayContainer} from '@angular/cdk/overlay';
import {ZendeskService} from './zendesk.service';
import {Message} from 'primeng/api';
import {CookieService} from 'ngx-cookie-service';

export interface IGlobalMessage {
  key: string;
  severity: 'success' | 'info' | 'warn' | 'error';
  summary: string;
  detail?: string;
}

@Injectable({providedIn: 'root'})
export class HeaderService {

  // static themePrefixKey = `${HeaderService.name}_themePrefix`;
  // static themeClassKey = `${HeaderService.name}_themeClass`;

  showMenu = true;
  isShowingSearch = false;
  visibleBackButton = false;
  isMobileView = new BehaviorSubject<boolean>(false);
  searchText = new BehaviorSubject<string | undefined>(undefined);
  currentMenu = new BehaviorSubject<MenuModel | undefined>(undefined);
  currentSubMenu = new BehaviorSubject<MenuModel | undefined>(undefined);
  themeClass = 'main-theme';
  themePrefix = '/';
  showInfoIcon = false;
  infoIconTooltip = '';
  infoIconClick = new EventEmitter();

  staticMessagesSubject = new BehaviorSubject<Array<Message>>([]);
  staticMessages: Array<Message> = [];
  closableMessagesSubject = new BehaviorSubject<Array<Message>>([]);
  closableMessages: Array<Message> = [];

  private allApps: MenuModel[] = [];
  private readonly _titleSub$ = new BehaviorSubject<string>(null);

  hasBetaModules = false;
  isBetaMenu = false;
  isMobileAppView = false;

  constructor(private location: Location,
              private router: Router,
              private authService: AuthService,
              @Inject(DOCUMENT) private document: any,
              private overlayContainer: OverlayContainer,
              private cookieService: CookieService,
              private zendeskService: ZendeskService) {

    console.log('init HeaderService');

    this.isMobileAppView = !!this.cookieService.get('x-mobile-app');
    console.log('isMobileAppView', this.isMobileAppView);

    this.document.body.classList.add('mat-typography');
    this.document.body.classList.add(this.themeClass);

    this.overlayContainer.getContainerElement().classList.remove('mat-typography');
    this.overlayContainer.getContainerElement().classList.remove('main-theme');

    const betaModules = RouterHelper.allRoutes.filter(x => x.data?.meta?.badge == 'beta');
    this.hasBetaModules = betaModules?.length > 0

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        for (const menuItem of this.getUserSideMenu()) {
          if (this.isCurrentRoute(menuItem)) {
            this.currentMenu.next(menuItem);

            for (const menuSubItem of menuItem.subItems || []) {
              if (this.isCurrentRoute(menuSubItem)) {
                this.isBetaMenu = menuSubItem.badge === 'beta';
                console.log('isBetaMenu', this.isBetaMenu);
                this.currentSubMenu.next(menuSubItem);
              }
            }

            break;
          }
        }
        console.log('themePrefix NavigationEnd', this.themePrefix, event.url);
        // if (!!this.themePrefix) {
        this.document.body.classList.add('mat-typography');
        this.document.body.classList.add(this.themeClass);

        this.overlayContainer.getContainerElement().classList.remove('mat-typography');
        this.overlayContainer.getContainerElement().classList.remove('main-theme');

        this.zendeskService.config(this.themeClass === 'main-theme' ? '#4862e5' : '#2e3d83');
      }

      if (event instanceof NavigationStart) {
        console.log('themePrefix NavigationStart', this.themePrefix, event.url);

        if (event.url.startsWith('/v2')) {
          const newRoute = event.url.replace('/v2', '');
          console.log('remove new ui', newRoute);
          await this.router.navigateByUrl(newRoute);
        }

        this.zendeskService.config(this.themeClass === 'main-theme' ? '#4862e5' : '#2e3d83');
      }
    });

    this.authService.currentUser.subscribe((user) => {
      const uiSettings = user?.uiSettings || {
        themePrefix: '/',
        themeClass: 'main-theme',
      };

      console.log(uiSettings);

      this.themePrefix = '/'; // uiSettings.themePrefix;
      this.themeClass = uiSettings.themeClass || 'main-theme';

      console.log('themePrefix currentUser changed', this.themePrefix, user);
      this.getUserApps(true);
    });

    this.staticMessagesSubject.subscribe((list) => {
      this.staticMessages = list;
    });

    this.closableMessagesSubject.subscribe((list) => {
      this.closableMessages = list;
    });
  }

  get title$(): Observable<string> {
    return this._titleSub$?.asObservable();
  }

  changeColorSchema(themeClass: string) {
    const newTheme = themeClass;

    this.authService.saveUiSettings({
      themeClass: newTheme,
      themePrefix: '/'
    }).then(() => {
      this.document.body.classList.remove('mat-typography');
      this.document.body.classList.remove('main-theme-dark');
      this.document.body.classList.remove('main-theme');

      this.overlayContainer.getContainerElement().classList.remove('mat-typography');
      this.overlayContainer.getContainerElement().classList.remove('main-theme');

      this.themeClass = newTheme;
      this.themePrefix = '/';

      this.zendeskService.config(this.themeClass === 'main-theme' ? '#4862e5' : '#2e3d83');
      // this.overlayContainer.getContainerElement().classList.add(this.themeClass);
      this.document.body.classList.add('mat-typography');
      this.document.body.classList.add(this.themeClass);
    });
  }

  toggleColorSchema() {
    const newTheme = this.themeClass === 'main-theme' ? 'main-theme-dark' : 'main-theme';
    this.changeColorSchema(newTheme);
  }

  public isCurrentRoute(item: MenuModel): boolean {
    let isCurrent = this.router.url.startsWith(item.url);
    item.isCurrent = false;
    if (item.subItems && item.subItems.length > 1) {
      isCurrent = !!item.subItems.find(x => this.router.url.startsWith(x.url));
      item.isCurrent = isCurrent;
      if (isCurrent) {
        item.toggle = isCurrent;
      }
    }

    return isCurrent;
  }

  setTitle(title: string) {
    this._titleSub$.next(title);
  }

  goBack() {
    console.log('location go back');
    this.location.back();
  }

  async goToComponent(componentName: string): Promise<boolean> {
    console.log('goToComponent', componentName);
    const module = RouterHelper.getRouteByComponent(componentName);
    if (module) {
      return await this.router.navigate([module.path]);
    }
    return false;
  }

  showBackButton(show: boolean) {
    this.visibleBackButton = show;
  }

  hideSideMenu() {
    this.showMenu = false;
    console.log('showMenu: ' + this.showMenu);
  }

  showSideMenu() {
    this.showMenu = true;
    console.log('showMenu: ' + this.showMenu);
  }

  toggleSideMenu() {
    this.showMenu = !this.showMenu;
    console.log('showMenu: ' + this.showMenu);
  }

  hideSearchInput() {
    this.isShowingSearch = false;
    this.isMobileView.next(this.isMobileView.getValue());
  }

  showSearchInput() {
    this.isShowingSearch = true;
    this.isMobileView.next(this.isMobileView.getValue());
  }

  basicPlanDescription(): string {
    return `
            <p>Basic SMS includes:</p>
            <ul>
                <li>Basic SMS messaging</li>
                <li>250 messages per month</li>
                <li>Each number can be registered to one user</li>
            </ul>
            <p><a href="javascript:void(0);" class="open-support-dialog"><b>Contact us</b></a> to learn more about upgrading you SMS package, to increase messaging count, add premium SMS features, and add unlimited users.</p>
          `;
  }

  getUserSideMenu(): MenuModel[] {
    const menuItems = this.getUserApps(false).filter((x) => !x.disabled && x.visible);

    const homeItem: MenuModel = {
      title: 'Main Page',
      url: `/home`,
      iconClass: 'material-icons',
      iconClassCode: 'apps',
      visible: true,
      subItems: [
        {
          title: 'Main Page',
          url: `/home`,
          iconClass: 'material-icons',
          iconClassCode: 'apps',
          visible: true,
        }
      ]
    };
    menuItems.unshift(homeItem);

    return menuItems;
  }

  getSubItems(group: MenuGroupEnum): MenuModel[] {
    const allMenuItems = RouterHelper.allRoutes;
    const menuItems: MenuModel[] = [];

    allMenuItems.forEach((route) => {
      if (route.data && route.data.meta) {
        const meta = route.data.meta;
        const otherMenuGroups = route.data.otherMenuGroups || [];
        if (meta.hasOwnProperty('inSideMenu') && meta.inSideMenu && (route.data.menuGroup === group || otherMenuGroups.includes(group))) {

          const currentUser = this.authService.currentUser.getValue();

          if (!currentUser) {
            return;
          }
          console.log(route);
          let accessLevel: 'full' | 'companyWide' | 'userWide' = 'full';
          let menuUrl = `${route.path}`;
          let queryParams: any = {};
          if (!this.authService.userHasPackage(route.data.package)) {
            let userPackage = route.data.package;
            if (Array.isArray(route.data.package)) {
              userPackage = route.data.package[0];
            }

            accessLevel = 'companyWide';
            menuUrl = `/forbidden/${userPackage.replace('Controller', '').toLowerCase()}`;
            queryParams = {type: 'disabled'};
          }

          if (!this.authService.userHasPermission(route.data.permissions)) {
            let userPackage = route.data.package;
            if (Array.isArray(route.data.package)) {
              userPackage = route.data.package[0];
            }

            accessLevel = 'userWide';
            menuUrl = `/forbidden/${userPackage.replace('Controller', '').toLowerCase()}`;
            queryParams = {type: 'disabled'};
          }

          if (accessLevel !== 'full' && !meta.showDisabled) {
            return;
          }

          const menuItem: MenuModel = {
            title: meta.title,
            url: menuUrl, // `${route.path}`, // ${this.themePrefix}
            queryParams: queryParams,
            iconClass: meta.iconClass,
            iconClassCode: meta.iconClassCode,
            accessLevel: accessLevel,
            showDisabled: meta.showDisabled,
            badge: meta.badge,
            order: meta.order || 0,
            /*description: meta.description*/
          };

          menuItems.push(menuItem);
          menuItems.sort((a, b) => a.order - b.order);
        }
      }
    });

    console.log('submenus:', menuItems);

    return menuItems;
  }

  getAppDefaultUrlByGroup(group: MenuGroupEnum): string {
    const settingsApp = this.getUserApps().find(x => x.group === group);

    if (!settingsApp) {
      return '';
    }

    return (settingsApp.subItems || []).length > 1 ? settingsApp.subItems![1].url : '';
  }

  getAppDefaultUrl(subItems: MenuModel[]): string {
    if (subItems.length > 0) {
      let activeItem = subItems.find(x => x.accessLevel === 'full');
      if (!activeItem) {
        activeItem = subItems.find(x => x.accessLevel !== 'full' && x.showDisabled);
      }

      return activeItem?.url || '';
    }

    return '';
  }

  addGlobalStaticMessage(message: IGlobalMessage) {
    console.log(`addGlobalStaticMessage ${message.key}`);
    this.removeGlobalStaticMessage(message.key);
    this.staticMessages.push(message);

    this.staticMessagesSubject.next(this.staticMessages);
  }

  removeGlobalStaticMessage(key: string) {
    console.log(`removeGlobalStaticMessage ${key}`);
    const oldMessageIndex = this.staticMessages.findIndex(x => x.key === key);
    if (oldMessageIndex !== -1) {
      this.staticMessages.splice(oldMessageIndex, 1);
      this.staticMessagesSubject.next(this.staticMessages);
    }
  }

  addGlobalClosableMessage(message: IGlobalMessage) {
    console.log(`addGlobalClosableMessage ${message.key}`);
    this.removeGlobalClosableMessage(message.key);
    this.closableMessages.push(message);

    this.closableMessagesSubject.next(this.closableMessages);
  }

  removeGlobalClosableMessage(key: string) {
    console.log(`removeGlobalClosableMessage ${key}`);
    const oldMessageIndex = this.closableMessages.findIndex(x => x.key === key);
    if (oldMessageIndex !== -1) {
      this.closableMessages.splice(oldMessageIndex, 1);
      this.closableMessagesSubject.next(this.closableMessages);
    }
  }

  hasClosableStaticMessages(): boolean {
    const hasStaticM = this.staticMessages?.length > 0
    const hasClosableM = this.closableMessages?.length > 0
    return hasStaticM || hasClosableM;
  }

  getUserApps(reload = false): MenuModel[] {
    const menuBadges: any = {};

    if (reload) {
      const appsWithBadges = this.allApps.filter(x => x.notificationBadge || 0 > 0);
      for (const appsWithBadge of appsWithBadges) {
        menuBadges[appsWithBadge.key || ''] = appsWithBadge.notificationBadge;
      }
      this.allApps = [];
    }

    if (this.allApps.length > 0) {
      return this.allApps;
    }

    const subItems0 = this.getSubItems(MenuGroupEnum.MessagingGroup);
    this.allApps.push({
      title: 'Basic SMS',
      key: 'sms',
      disabled: false,
      group: MenuGroupEnum.MessagingGroup,
      subItems: subItems0,
      visible: !!this.getAppDefaultUrl(subItems0),
      iconClass: 'material-icons',
      iconClassCode: 'textsms',
      color: '#007a99',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems0),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'Send and receive professional text messages from your business number(s).',
      notificationBadge: menuBadges['sms'] || 0
    });

    const subItems01 = this.getSubItems(MenuGroupEnum.ProMessagingGroup);
    this.allApps.push({
      title: 'Pro Business SMS',
      key: 'prosms',
      disabled: false,
      group: MenuGroupEnum.ProMessagingGroup,
      subItems: subItems01,
      visible: !!this.getAppDefaultUrl(subItems01),
      iconClass: 'material-icons',
      iconClassCode: 'try',
      color: '#007a99',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems01),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'Send and receive professional text messages from your business number(s).',
      notificationBadge: menuBadges['prosms'] || 0
    });

    const subItems1 = this.getSubItems(MenuGroupEnum.CallLogsGroup);
    this.allApps.push({
      title: 'My Call Logs',
      disabled: false,
      group: MenuGroupEnum.CallLogsGroup,
      subItems: subItems1,
      visible: !!this.getAppDefaultUrl(subItems1),
      iconClass: 'material-icons',
      iconClassCode: 'description',
      color: '#05a380',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems1),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'View up to 90 days call history made to and from your phone system. ' +
        'You can also select a customized date range and easily export with a click of a button',
    });

    const subItems2 = this.getSubItems(MenuGroupEnum.ReportsGroup);
    this.allApps.push({
      title: 'Analytics',
      disabled: false,
      group: MenuGroupEnum.ReportsGroup,
      subItems: subItems2,
      visible: !!this.getAppDefaultUrl(subItems2),
      iconClass: 'material-icons',
      iconClassCode: 'poll',
      color: '#efb523',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems2),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'Access your call data and gain valuable insights into your business on a single screen, ' +
        'including charts, graphs, and logs tables'
    });

    const subItems2_2 = this.getSubItems(MenuGroupEnum.LegacyReportsGroup);
    this.allApps.push({
      title: 'Analytics (Legacy)',
      disabled: false,
      group: MenuGroupEnum.LegacyReportsGroup,
      subItems: subItems2_2,
      visible: !!this.getAppDefaultUrl(subItems2_2),
      iconClass: 'material-icons',
      iconClassCode: 'poll',
      color: '#efb523',
      badge: 'deprecated',
      url: this.getAppDefaultUrl(subItems2_2),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'Access your call data and gain valuable insights into your business on a single screen, ' +
        'including charts, graphs, and logs tables'
    });

    const subItems3 = this.getSubItems(MenuGroupEnum.PhoneNumbersGroup);
    this.allApps.push({
      title: 'Phone Numbers',
      disabled: false,
      group: MenuGroupEnum.PhoneNumbersGroup,
      subItems: subItems3,
      visible: !!this.getAppDefaultUrl(subItems3),
      iconClass: 'fa fa-hashtag',
      // iconClassCode: 'settings_phone',
      color: '#007a99',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems3),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'View your account phone numbers, including fax numbers. ' +
        'You can also set your Company caller ID to your Company Name ' +
        'so that your customers will know who is calling when you call them.'
    });

    /*const subItems4 = this.getSubItems(MenuGroupEnum.FirewallGroup);
    this.allApps.push({
      title: 'IP Whitelist',
      disabled: false,
      group: MenuGroupEnum.FirewallGroup,
      subItems: subItems4,
      visible: !!this.getAppDefaultUrl(subItems4),
      iconClass: 'material-icons',
      iconClassCode: 'security',
      color: '#ff684a',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems4),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'Allowing phones outside of the U.S to access the phone system.'
    });*/

    const subItems5 = this.getSubItems(MenuGroupEnum.FaxGroup);
    this.allApps.push({
      title: 'Fax',
      key: 'fax',
      disabled: false,
      subItems: subItems5,
      group: MenuGroupEnum.FaxGroup,
      visible: !!this.getAppDefaultUrl(subItems5),
      iconClass: 'material-icons',
      iconClassCode: 'print',
      color: '#efb523',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems5),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      notificationBadge: menuBadges['fax'] || 0
    });

    const subItems6 = this.getSubItems(MenuGroupEnum.PbxGroup);
    this.allApps.push({
      title: 'Phone System',
      key: 'pbx',
      disabled: false,
      visible: !!this.getAppDefaultUrl(subItems6),
      subItems: subItems6,
      group: MenuGroupEnum.PbxGroup,
      iconClass: 'material-icons',
      iconClassCode: 'cloud_circle',
      color: '#007a99',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems6),
      anchor: _.uniqueId('anchor_'),
      showPopup: false
    });

    const subItems7 = this.getSubItems(MenuGroupEnum.ContactGroup);
    this.allApps.push({
      title: 'Contacts',
      key: 'contacts',
      disabled: false,
      visible: !!this.getAppDefaultUrl(subItems7),
      subItems: subItems7,
      group: MenuGroupEnum.ContactGroup,
      iconClass: 'material-icons',
      iconClassCode: 'group',
      color: '#05a380',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems7),
      anchor: _.uniqueId('anchor_'),
      showPopup: false
    });

    // const subItems8 = this.getSubItems(MenuGroupEnum.ContactGroup);
    /*this.allApps.push({
      title: 'Billing',
      disabled: true,
      visible: true,
      iconClass: 'material-icons',
      iconClassCode: 'monetization_on',
      color: '#05a380',
      badge: 'soon',
      url: '',
      anchor: _.uniqueId('anchor_'),
      showPopup: false
    });*/

    const subItems9 = this.getSubItems(MenuGroupEnum.SoftPhoneGroup);
    this.allApps.push({
      title: 'Phone App',
      key: 'softphone',
      disabled: false,
      visible: !!this.getAppDefaultUrl(subItems9),
      subItems: subItems9,
      group: MenuGroupEnum.SoftPhoneGroup,
      iconClass: 'material-icons',
      iconClassCode: 'phone_iphone',
      color: '#efb523',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems9),
      anchor: _.uniqueId('anchor_'),
      showPopup: false
    });

    const subItems12 = this.getSubItems(MenuGroupEnum.EventsFlowGroup);
    this.allApps.push({
      title: 'Events Flow',
      key: 'eventsflow',
      disabled: false,
      visible: !!this.getAppDefaultUrl(subItems12),
      subItems: subItems12,
      group: MenuGroupEnum.EventsFlowGroup,
      iconClass: 'material-icons',
      iconClassCode: 'account_tree',
      color: '#007bff',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems12),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      notificationBadge: 0
    });

    const subItems11 = this.getSubItems(MenuGroupEnum.DownloadsGroup);
    this.allApps.push({
      title: 'Downloads',
      key: 'downloads',
      disabled: false,
      visible: !!this.getAppDefaultUrl(subItems11),
      subItems: subItems11,
      group: MenuGroupEnum.DownloadsGroup,
      iconClass: 'material-icons',
      iconClassCode: 'download',
      color: '#efb523',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems11),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      notificationBadge: menuBadges['downloads'] || 0
    });

    const subItems10 = this.getSubItems(MenuGroupEnum.VideoConferenceGroup);
    this.allApps.push({
      title: 'Conference',
      key: 'videoconference',
      disabled: false,
      visible: !!this.getAppDefaultUrl(subItems10),
      subItems: subItems10,
      group: MenuGroupEnum.VideoConferenceGroup,
      iconClass: 'material-icons',
      iconClassCode: 'voice_chat',
      color: '#0e72ed',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems10),
      anchor: _.uniqueId('anchor_'),
      showPopup: false
    });


    /*this.allApps.push({
      title: 'New Orders',
      disabled: true,
      visible: true,
      iconClass: 'material-icons',
      iconClassCode: 'add_shopping_cart',
      color: '#ff684a',
      badge: 'soon',
      url: '',
      anchor: _.uniqueId('anchor_'),
      showPopup: false
    });*/

    const subItems99 = this.getSubItems(MenuGroupEnum.SettingsGroup);
    this.allApps.push({
      title: 'Admin Settings',
      disabled: false,
      group: MenuGroupEnum.SettingsGroup,
      subItems: subItems99,
      visible: !!this.getAppDefaultUrl(subItems99),
      forceHideApp: true,
      iconClass: 'material-icons',
      iconClassCode: 'settings',
      color: '#05a380',
      badge: 'none',
      url: this.getAppDefaultUrl(subItems99),
      anchor: _.uniqueId('anchor_'),
      showPopup: false,
      description: 'Allowing users to set company wide settings.'
    });

    return this.allApps;
  }

}
